<template>
  <div>
    <!--begin::Body-->
    <div class="kt-login__body">
      <!--begin::Signin-->
      <div class="kt-login__form">
        <div class="kt-login__title">
          <h3>Sign In</h3>
        </div>

        <!--begin::Form-->
        <b-form class="kt-form" @submit.stop.prevent="onSubmit">
          <div role="alert" class="alert alert-info">
            <div class="alert-text">
              Nhập tên đăng nhập và mật khẩu để đăng nhập
            </div>
          </div>
          <b-form-group
            id="example-input-group-1"
            label=""
            label-for="example-input-1"
          >
            <b-form-input
              id="example-input-1"
              name="example-input-1"
              placeholder="Nhập nick name"
              v-model="$v.form.nick_name.$model"
              :state="validateState('nick_name')"
              aria-describedby="input-1-live-feedback"
            ></b-form-input>

            <b-form-invalid-feedback id="input-1-live-feedback">
              Tên đăng nhập là bắt buộc
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            id="example-input-group-2"
            label=""
            label-for="example-input-2"
          >
            <b-form-input
              type="password"
              id="example-input-2"
              name="example-input-2"
              placeholder="Nhập mật khẩu"
              v-model="$v.form.password.$model"
              :state="validateState('password')"
              aria-describedby="input-2-live-feedback"
            ></b-form-input>

            <b-form-invalid-feedback id="input-2-live-feedback">
              Mật khẩu là bắt buộc
            </b-form-invalid-feedback>
          </b-form-group>

          <!--begin::Action-->
          <div class="kt-login__actions">
            <a href="#" class="kt-link kt-login__link-forgot">
              {{ $t("AUTH.FORGOT.TITLE") }}
            </a>
            <b-button
              type="submit"
              id="kt_submit"
              class="btn btn-primary btn-elevate kt-login__btn-primary"
            >
              {{ $t("AUTH.LOGIN.BUTTON") }}
            </b-button>
          </div>
          <!--end::Action-->
        </b-form>
        <!--end::Form-->
        <!--end::Options-->
      </div>
      <!--end::Signin-->
    </div>
    <!--end::Body-->
    <hnr-alert :type="type" :message="message"></hnr-alert>
  </div>
  
</template>

<style lang="scss" scoped>
.kt-spinner.kt-spinner--right:before {
  right: 8px;
}
</style>

<script>
import { mapState, mapGetters } from "vuex";
import { LOGIN, LOGOUT } from "@/store/auth.module";
import { ERROR } from "@/store/alert.module";

import { validationMixin } from "vuelidate";
import { minLength, required } from "vuelidate/lib/validators";
import HnrAlert from '@/components/elements/hnr-alert'

export default {
  mixins: [validationMixin],
  name: "login",
  data() {
    return {
      // Remove this dummy login info
      form: {
        nick_name: "",
        password: ""
      }
    };
  },
  components: {
    HnrAlert
  },
  validations: {
    form: {
      nick_name: {
        required,
      },
      password: {
        required,
        minLength: minLength(3)
      }
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        nick_name: null,
        password: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const nick_name = this.$v.form.nick_name.$model;
      const password = this.$v.form.password.$model;

      // clear existing errors
      this.$store.dispatch(LOGOUT);

      // set spinner to submit button
      const submitButton = document.getElementById("kt_submit");
      submitButton.classList.add(
        "kt-spinner",
        "kt-spinner--light",
        "kt-spinner--right"
      );

      // dummy delay
      setTimeout(() => {
        // send login request
        this.$store
          .dispatch(LOGIN, { nick_name, password })
          // go to which page after successfully login
          .then(data => {
            if (data.status) {
              this.$router.push({ name: "dashboard" })
            }
            else {
              this.$store.dispatch(ERROR, "Đăng nhập thất bại!", { root: true });
            }
          });

        submitButton.classList.remove(
          "kt-spinner",
          "kt-spinner--light",
          "kt-spinner--right"
        );
      }, 1000);
    }
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
     ...mapGetters([
      "type",
      "message"
    ]),
    backgroundImage() {
      return process.env.BASE_URL + "assets/media/bg/bg-4.jpg";
    }
  }
};
</script>
